import React from "react";

const AbiturientIcon = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 10.2185V10.0385C1.01823 8.70153 1.48251 7.40897 2.31915 6.36598C3.15579 5.32299 4.31684 4.58934 5.618 4.28149L5.794 4.24149L5.961 4.20549C8.62254 3.62647 11.3775 3.62647 14.039 4.20549L14.206 4.24249L14.382 4.28249C15.7129 4.59733 16.8963 5.35739 17.7362 6.43676C18.576 7.51614 19.0219 8.85004 19 10.2175V15.6815C18.9999 16.7095 18.6494 17.7068 18.0062 18.5087C17.3629 19.3107 16.4655 19.8693 15.462 20.0925C11.864 20.8925 8.136 20.8925 4.539 20.0925C3.53564 19.8696 2.63824 19.3113 1.99486 18.5098C1.35148 17.7082 1.00056 16.7113 1 15.6835V10.2185Z" />
      <path
        d="M15.5 14.8075V16.3075M13.959 3.8075C13.8408 3.10829 13.4788 2.47355 12.9371 2.0159C12.3954 1.55825 11.7091 1.30727 11 1.3075H9C8.29087 1.30727 7.60459 1.55825 7.06291 2.0159C6.52124 2.47355 6.15919 3.10829 6.041 3.8075M1 13.3075C3.83335 14.5668 6.89942 15.2174 10 15.2174C13.1006 15.2174 16.1667 14.5668 19 13.3075M8 12.3075H12"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AbiturientIcon;
