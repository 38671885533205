import React, { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import { baseURL } from "./Api/config";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Accounts/Login/Login";
import { EnterLk } from "./components/EnterLk/EnterLk";
import { NotFound } from "./components/EnterLk/404page";
import { ApiData } from "./@types/type";
import { DataContext, defaultValue } from "./context/context";

function App() {
  const [data, setData] = useState<ApiData>(defaultValue);

  useEffect(() => {
    axios.get(`${baseURL}csrf/`).catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <DataContext.Provider value={[data, setData]}>
      <Routes>
        <Route path={"/"} element={<Login />} />
        <Route path={"/enter_lk"} element={<EnterLk />} />
        <Route path={"/404"} element={<NotFound />} />
      </Routes>
    </DataContext.Provider>
  );
}

export default App;
