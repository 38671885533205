import React from "react";
import { SyncLoader } from "react-spinners";

const LoadingComponent = () => {
  return (
    <div className="d-flex justify-content-center">
      <SyncLoader color="#1C00EC" />
    </div>
  );
};

export default LoadingComponent;
