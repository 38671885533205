import React from "react";
import { ApiData } from "../@types/type";

export const defaultValue: ApiData = {
  abiturient: false,
  email: "",
  employee: false,
  student: false,
  job: false,
};
export const DataContext = React.createContext<
  [ApiData, React.Dispatch<ApiData>]
>([defaultValue, () => defaultValue]);
