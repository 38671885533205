import { Col, Button, Row } from "react-bootstrap";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import HeaderLink from "../../Header/HeaderLink";
import UserIcon from "../../../assets/Icons/UserIcon";
import { sendTokenOnBE } from "../../../Api/http/userApi";
import { DataContext } from "../../../context/context";
import ChangeLanguage from "../../ChangeLanguage/ChangeLanguage";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [, setData] = useContext(DataContext);
  const { t, i18n } = useTranslation();
  document.cookie = "from_my=True; path=/";

  const login = useCallback(() => {
    keycloak?.login();
  }, [keycloak]);

  useEffect(() => {
    if (keycloak?.authenticated) {
      const keycloakAccessToken = keycloak["token"];
      const keycloakRefreshToken = keycloak["refreshToken"];
      const authenticated = keycloak["authenticated"];
      const data = {
        keycloakAccessToken: keycloakAccessToken,
        keycloakRefreshToken: keycloakRefreshToken,
        authenticated: authenticated,
      };
      sendTokenOnBE(data).then((data) => {
        setData(data);
        navigate("/enter_lk");
      });
    }
  }, [keycloak, keycloak?.authenticated, navigate]);

  return (
    <Row className="vh-100">
      <Col
        lg={4}
        xs={12}
        className="d-flex justify-content-center align-items-center"
      >
        <div className={styles.auth}>
          <div className="mb-5">
            <HeaderLink />
          </div>
          <h2 className={`${styles.user} text-center mb-6`}>
            {t("personalAccount")}
          </h2>
          <div className="mb-6">
            <UserIcon />
          </div>
          <Button
            variant="primary"
            size="lg"
            type="submit"
            className="w-100"
            onClick={login}
          >
            {t("login")}
          </Button>
          <ChangeLanguage />
          {i18n.language === "ru" ? (
            <Row className="mt-3">
              <Button
                variant="secondary"
                size="sm"
                className={`w-100 ${styles.link}`}
                onClick={() =>
                  window.open("https://youtu.be/hDEKk2kh4n0", "_blank")
                }
              >
                Видеогид: как подать заявление через личный кабинет абитуриента
                из стран СНГ
              </Button>
              <Button
                variant="secondary"
                size="sm"
                className={`w-100 mt-3 ${styles.link}`}
                onClick={() =>
                  window.open("https://youtu.be/pf21ivqCPjQ", "_blank")
                }
              >
                Видеогид: подача заявления в магистратуру МАИ
              </Button>
            </Row>
          ) : (
            <Row className="mt-3">
              <Button
                variant="secondary"
                size="sm"
                className={`w-100 ${styles.link}`}
                onClick={() =>
                  window.open("https://youtu.be/j-39Wv4A2xY", "_blank")
                }
              >
                Videoguide for international students applying at MAl
              </Button>
            </Row>
          )}
        </div>
      </Col>
      <Col lg={8} className={styles.banner} />
    </Row>
  );
};

export default Login;
