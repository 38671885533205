import React from "react";

const HeaderIcon = () => {
  return (
    <svg
      width="63"
      height="60"
      viewBox="0 0 63 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_867_9610)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.3786 0C58.2793 0 65.5401 10.4011 62.25 25.5324C58.6465 42.0775 46.7015 53.8213 32.0067 60C13.4469 52.2939 2.75942 36.9837 0.799988 16.3047C8.99346 14.9408 15.4429 9.18919 14.9871 0H44.3786Z"
          fill="#0095DA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.056 25.0457C56.9409 39.6711 46.3707 51.3494 32.0163 57.557C15.1275 50.301 5.4034 35.9681 3.27667 18.0263C10.8183 16.1027 16.6716 10.4222 17.1946 2.20832H44.4767C57.8466 2.14869 62.823 12.6652 60.056 25.0457Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.112 24.6148C55.0277 38.8305 45.0959 49.4335 32.0221 55.3756C17.1715 48.7641 7.88394 35.945 5.46686 19.4595C12.4547 17.0703 17.783 11.6842 18.9771 4.22623H44.4844C56.5679 4.1666 60.531 13.5462 58.112 24.6148Z"
          fill="#0095DA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.7474 21.1215H29.2646L34.8314 12.8652L38.3791 11.6418C38.8272 11.4879 38.7003 11.1667 38.4695 11.1667H35.141L34.7814 7.5541C34.7679 7.36173 34.4603 7.28286 34.3814 7.54255L33.2892 11.1667H29.8165C29.5204 11.1667 29.4838 11.5129 29.8165 11.6418L32.9796 12.7864L25.5361 21.1215H9.7972C9.17995 21.1215 9.09149 22.1641 9.7972 22.2141L23.469 23.9376C23.0056 24.7744 22.2345 25.5843 21.9634 26.5211C21.6807 27.4983 22.4402 28.2466 23.1979 28.0773C24.4189 27.808 26.438 25.2746 27.261 24.2589L27.5802 23.7607L48.7493 22.1775C49.4954 22.1391 49.48 21.1215 48.7474 21.1215Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.9841 37.3512C26.8437 37.8725 26.7264 38.2418 26.6226 38.5265C26.6226 37.6359 26.6226 34.3137 26.6226 34.2656C27.0437 34.2426 27.6263 34.2156 27.6263 34.2156L27.6975 32.7806V30.2876H21.8057C21.8057 30.2876 21.1385 32.0515 20.9539 32.544C20.7674 32.0515 20.1001 30.2876 20.1001 30.2876H14.0911V34.2118C14.0911 34.2118 14.7314 34.2445 15.1698 34.2656C15.1698 34.3176 15.1679 39.0305 15.1679 39.0709C14.7718 39.094 50.0358 39.0959 49.5974 39.0728C49.5974 39.017 49.5974 34.3176 49.5974 34.2656C50.0185 34.2426 50.5992 34.2156 50.5992 34.2156L50.6742 32.7806V30.2876H37.91V34.2137C37.91 34.2137 38.5849 34.2483 39.0176 34.2676C39.0176 34.3214 39.0176 38.3957 39.0176 38.8477C38.8984 38.6073 38.7369 38.1283 38.4946 37.0742L34.418 19.6499L31.4933 19.621C31.4914 19.623 26.9879 37.3242 26.9841 37.3512ZM32.6509 38.3361C32.7086 38.6208 32.7547 38.8631 32.7874 39.069C32.572 39.0767 32.4586 39.0805 32.1836 39.092V39.0555C32.1836 39.0555 31.4394 39.0555 31.026 39.0555C31.0472 38.9689 31.1183 38.6881 31.2087 38.3361C31.824 38.3361 32.0298 38.3361 32.6509 38.3361Z"
          fill="#0095DA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5911 35.7238L32.1067 28.414L30.3607 35.7238H33.5911ZM42.5865 38.0264L46.0246 33.829V33.6848C46.0246 33.2192 45.7612 32.7806 44.917 32.7806V31.7938H49.1705V32.7806C48.1225 32.7806 48.0917 33.1904 48.0917 34.2388V39.1017C48.0917 40.1809 48.1782 40.5598 49.1705 40.5598V41.5197H44.917V40.5598C45.8785 40.5598 46.0246 40.2097 46.0246 39.1017V35.3179L42.5865 39.5403V39.7134C42.5865 40.2963 42.9095 40.5579 43.694 40.5579V41.5178H33.1239V40.5579C33.8815 40.5579 34.3757 40.5002 34.3757 39.7403C34.3757 39.2768 34.1719 38.1091 33.8526 36.828H30.0395L29.5434 38.7805C29.1954 40.0616 29.3088 40.5579 30.6779 40.5579V41.5178H21.8557V40.5579C22.9037 40.5579 23.019 40.2078 23.019 39.0998V34.5562L20.3404 41.2004L17.6311 34.3523V39.1883C17.6311 40.1193 17.7484 40.5579 18.7656 40.5579V41.5178H15.5101V40.5579C16.6158 40.5579 16.6735 39.8865 16.6735 38.9536V34.2368C16.6735 33.3943 16.6735 32.7787 15.5967 32.7787V31.7919H19.0598L20.9539 36.801L22.846 31.7919H26.1918V32.7787C25.1458 32.7787 25.115 33.1885 25.115 34.2368V39.0998C25.115 40.0327 25.2015 40.3251 25.4938 40.4136C26.1341 40.6464 27.3302 40.5867 27.6763 39.8885C27.9109 39.4518 28.1724 38.7247 28.4339 37.734L32.947 19.9943L37.0235 37.4128C37.46 39.3056 37.7234 39.7711 38.1311 40.1789C38.5387 40.529 39.8771 40.7907 40.3424 40.2674C40.4597 40.1501 40.5155 39.9462 40.5155 39.1017V34.2388C40.5155 33.1307 40.429 32.7806 39.4117 32.7806V31.7938H43.6921V32.7806C42.6441 32.7806 42.5845 33.2192 42.5845 34.2388V38.0264H42.5865Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_867_9610">
          <rect
            width="62"
            height="60"
            fill="white"
            transform="translate(0.799988)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HeaderIcon;
