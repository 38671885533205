import React from "react";

const ExitIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.6515 9.92405H0.833313M0.833313 9.92405L4.01513 7.19678M0.833313 9.92405L4.01513 12.6513"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.19879 5.37871C7.2097 3.40143 7.29788 2.33052 7.99606 1.63234C8.79515 0.833252 10.0806 0.833252 12.6515 0.833252H13.5606C16.1324 0.833252 17.4179 0.833252 18.217 1.63234C19.0152 2.43052 19.0152 3.71689 19.0152 6.2878V13.5605C19.0152 16.1314 19.0152 17.4178 18.217 18.216C17.5179 18.9151 16.447 19.0023 14.4697 19.0133M7.19879 14.4696C7.2097 16.4469 7.29788 17.5178 7.99606 18.216C8.57879 18.7996 9.42152 18.9569 10.8333 18.9996"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ExitIcon;
