import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./scss/app.scss";
import App from "./App";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingComponent from "./components/LoadingComponent";
import keycloak from "./components/Accounts/Login/KeycloakConfig";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <ReactKeycloakProvider authClient={keycloak}>
    <Suspense fallback={<LoadingComponent />}>
      <BrowserRouter>
        <Routes>
          <Route path={"/*"} element={<App />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  </ReactKeycloakProvider>,
);
