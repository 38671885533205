import React from "react";

const WorkIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 6H10C8.644 6 7.532 6 6.61 6.054C4.965 6.151 3.922 6.421 3.172 7.172C2 8.343 2 10.229 2 14C2 17.771 2 19.657 3.172 20.828C4.343 22 6.229 22 10 22H14C17.771 22 19.657 22 20.828 20.828C22 19.657 22 17.771 22 14C22 10.229 22 8.343 20.828 7.172C20.078 6.422 19.035 6.152 17.391 6.054C16.468 6 15.356 6 14 6Z" />
      <path d="M6.60999 6.054C7.43299 6.034 8.15998 5.454 8.43998 4.68L8.47499 4.577L8.49999 4.5C8.54199 4.373 8.56399 4.31 8.58599 4.254C8.72725 3.90281 8.96527 3.59892 9.2724 3.37765C9.57952 3.15638 9.94314 3.02681 10.321 3.004C10.38 3 10.448 3 10.58 3H13.418C13.551 3 13.618 3 13.678 3.004C14.0558 3.02681 14.4194 3.15638 14.7266 3.37765C15.0337 3.59892 15.2717 3.90281 15.413 4.254C15.436 4.31 15.457 4.374 15.499 4.5L15.525 4.577C15.543 4.63 15.551 4.657 15.56 4.68C15.84 5.455 16.567 6.034 17.39 6.054" />
      <path
        d="M21.662 8.72C18.652 10.676 17.147 11.654 15.561 12.147C13.242 12.8675 10.759 12.8675 8.44001 12.147C6.85301 11.654 5.34801 10.677 2.33801 8.72M8.00001 11V13M16 11V13"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default WorkIcon;
