import axios from "axios";
import { baseURL } from "./config";

const $api = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

$api.interceptors.response.use(
  function (response) {
    const responseTime = response.headers.time || 0;
    localStorage.setItem("time", String(responseTime));
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.setItem("time", "0");
      window.location.href = "/";
    }
    if (error.response?.status === 404) {
      localStorage.setItem("time", "0");
      return;
      //window.location.href = "/";
    }
    return Promise.reject(error);
  },
);

export default $api;
