import React from "react";
import styles from "../Header.module.scss";
import HeaderIcon from "../../../assets/Icons/HeaderIcon";
import HeaderIconEn from "../../../assets/Icons/HeaderIconEn";
import { useTranslation } from "react-i18next";

const HeaderLink = () => {
  const { i18n } = useTranslation();
  return (
    <a href="/" className={styles.headerLink}>
      {i18n.language === "ru" ? <HeaderIcon /> : <HeaderIconEn />}
      {/*<span>Московский авиационный институт</span>*/}
    </a>
  );
};

export default HeaderLink;
