import HeaderLink from "../Header/HeaderLink";
import React from "react";

const NotFound = () => {
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center flex-column">
      <div className="mb-5">
        <HeaderLink />
      </div>
      <h1>В разработке</h1>
    </div>
  );
};

export { NotFound };
