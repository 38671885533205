import React from "react";

const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
    >
      <g filter="url(#filter0_d_2202_3239)">
        <circle cx="44" cy="40" r="40" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44 74C52.3379 74 59.9748 70.9987 65.8893 66.0175C66.2743 61.9602 64.5778 59.0224 62.3254 57.1311C61.1166 56.1161 59.758 55.4172 58.5034 55.0425C57.5434 54.7557 56.754 54.6894 56.1924 54.7359C52.1591 58.3789 47.1759 59.262 42.9733 58.7994C40.7716 58.5571 38.7159 57.942 37.0195 57.1063C35.8817 56.5458 34.7854 55.8258 33.9263 54.9531C31.5644 55.1066 29.8447 55.7291 28.5809 56.5544C27.1187 57.5093 26.0993 58.845 25.4129 60.4238C24.429 62.6866 24.1743 65.3587 24.3124 67.7233C29.868 71.6757 36.6626 74 44 74ZM65.5406 53.3019C67.8204 55.2161 69.7188 57.9018 70.5268 61.27C75.2025 55.4462 78 48.0498 78 40C78 21.2223 62.7777 6 44 6C25.2223 6 10 21.2223 10 40C10 49.1348 13.6024 57.4282 19.4641 63.537C19.683 61.8221 20.1106 60.0791 20.8275 58.4302C21.8345 56.1142 23.4326 53.9447 25.8469 52.368C28.2616 50.7911 31.3253 49.9165 35.0845 49.9165H36.4874L37.2183 51.114C37.4054 51.4204 38.013 52.022 39.2292 52.6211C40.385 53.1905 41.8757 53.6484 43.5203 53.8294C46.8076 54.1913 50.4365 53.4328 53.2445 50.643L53.7025 50.1879L54.3237 50.0116C56.0658 49.5169 58.072 49.6953 59.9346 50.2517C61.8383 50.8204 63.8051 51.8446 65.5406 53.3019ZM44 80C66.0914 80 84 62.0914 84 40C84 17.9086 66.0914 0 44 0C21.9086 0 4 17.9086 4 40C4 62.0914 21.9086 80 44 80ZM52.5055 31.6483C52.5055 36.103 48.8942 39.7143 44.4396 39.7143C39.9849 39.7143 36.3736 36.103 36.3736 31.6483C36.3736 27.1937 39.9849 23.5824 44.4396 23.5824C48.8942 23.5824 52.5055 27.1937 52.5055 31.6483ZM58.5055 31.6483C58.5055 39.4168 52.208 45.7143 44.4396 45.7143C36.6712 45.7143 30.3736 39.4168 30.3736 31.6483C30.3736 23.8799 36.6712 17.5824 44.4396 17.5824C52.208 17.5824 58.5055 23.8799 58.5055 31.6483Z"
          fill="url(#paint0_linear_2202_3239)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2202_3239"
          x="0"
          y="0"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.6 0 0 0 0 0.6 0 0 0 0 0.6 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2202_3239"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2202_3239"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2202_3239"
          x1="19.8242"
          y1="17.1429"
          x2="67.7363"
          y2="64.6154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C00EC" />
          <stop offset="1" stopColor="#007BFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UserIcon;
