import React from "react";

const StudentIcon = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.783 1.49973C9.47581 1.17087 10.2331 1.00026 11 1.00026C11.7669 1.00026 12.5242 1.17087 13.217 1.49973L19.908 4.63673C21.364 5.31873 21.364 7.68073 19.908 8.36273L13.218 11.4997C12.5251 11.8287 11.7676 11.9994 11.0005 11.9994C10.2334 11.9994 9.47594 11.8287 8.783 11.4997L2.092 8.36273C0.636 7.68073 0.636 5.31873 2.092 4.63673L8.783 1.49973Z" />
      <path
        d="M1 6.49974V11.9997M18 9.49974V14.6247C18 15.6327 17.497 16.5767 16.615 17.0647C15.146 17.8797 12.796 18.9997 11 18.9997C9.204 18.9997 6.854 17.8787 5.385 17.0657C4.504 16.5767 4 15.6327 4 14.6257V9.49974"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default StudentIcon;
