import React from "react";

const HeaderIconEn = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.22 88.87">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Слой_1" data-name="Слой 1">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#0095DA"
              d="M64.57,0c20.6,0,31.36,15.4,26.49,37.82-5.35,24.5-23,41.9-44.82,51.05C18.74,77.46,2.9,54.78,0,24.15,12.14,22.13,21.7,13.61,21,0Z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="white"
              d="M87.63,37.27C83,58.93,67.35,76.23,46.08,85.43c-25-10.75-39.44-32-42.58-58.56C14.67,24,23.34,15.61,24.12,3.45H64.55c19.8-.09,27.18,15.49,23.08,33.82"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#0095DA"
              d="M84.61,36.76C80,57.81,65.33,73.52,46,82.32c-22-9.8-35.81-28.78-39.4-53.2C17,25.58,24.86,17.6,26.62,6.55h37.8C82.33,6.47,88.2,20.36,84.61,36.76"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="white"
              d="M49.75,31H42.53l8.55-12.67,5.45-1.87c.69-.24.49-.73.13-.73H51.55L51,10.17a.31.31,0,0,0-.36-.25.3.3,0,0,0-.25.25l-1.68,5.57H43.38c-.45,0-.51.53,0,.73l4.86,1.75L36.81,31H12.64a.84.84,0,1,0,0,1.68l21,2.65c-.71,1.28-1.89,2.52-2.31,4a1.76,1.76,0,0,0,1.9,2.39c1.87-.41,5-4.3,6.23-5.86l.49-.77,8.72-.68Z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="white"
              d="M76.19,31H53l.79,3.1,22.45-1.48a.81.81,0,1,0,0-1.62"
            />
            <path
              fill="#FFF"
              d="M66.57,49.14a.88.88,0,0,1,.4-.49l.15-.08a9.61,9.61,0,0,1,1.93-.16V46.87H60.52v1.55h.33a12.08,12.08,0,0,1,1.47.09h.1a.66.66,0,0,1,.19.09l.21.18a3,3,0,0,1,.34,1.87V58.3c0,1.32-.09,1.64-.28,1.82a1,1,0,0,1-.25.2l-.12.07h-.12a3.67,3.67,0,0,1-3-.39c-.63-.64-1-1.36-1.73-4.32L51.33,28.42,44.27,56.16a17.53,17.53,0,0,1-1.19,3.37c-.55,1.09-2.41,1.18-3.42.82-.45-.14-.59-.6-.59-2.05V50.69c0-1.64,0-2.28,1.69-2.28V46.87H35.52l-3,7.83-3-7.83H24.18v1.54c1.68,0,1.68,1,1.68,2.28v7.38c0,1.46-.09,2.51-1.82,2.51v1.5h5.1v-1.5c-1.59,0-1.77-.69-1.77-2.15V50.87L31.6,61.58l4.19-10.39V58.3c0,1.73-.18,2.28-1.82,2.28v1.5h13.8v-1.5c-2.14,0-2.32-.78-1.78-2.78l.78-3.06h6a26.11,26.11,0,0,1,.82,4.56c0,1.18-.78,1.28-2,1.28v1.5H69.05v-1.5H67.69a1.27,1.27,0,0,1-1.18-.69,4.2,4.2,0,0,1-.05-1V50.06A3.18,3.18,0,0,1,66.57,49.14ZM47.27,53,50,41.58,52.33,53Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HeaderIconEn;
