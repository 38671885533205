import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "react-bootstrap";
import s from "./ChangeLanguage.module.scss";

const languages = [
  {
    value: "ru",
    label: "RU",
  },
  {
    value: "en",
    label: "EN",
  },
];

export default function ChangeLanguage() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className={s.body}>
      <div className="d-flex justify-content-center mb-2">
        {t("chLanguage")}
      </div>
      <ButtonGroup size="sm">
        {languages.map((language, index) => (
          <Button
            key={index}
            variant="secondary"
            size="sm"
            className={s.button}
            style={
              i18n.language !== language.value
                ? { fontWeight: "400" }
                : undefined
            }
            onClick={() => changeLanguage(language.value)}
          >
            {language.label}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}
