import Keycloak from "keycloak-js";

const url = String(process.env.REACT_APP_KEYCLOAK_URL);
const realm = String(process.env.REACT_APP_KEYCLOAK_REALM);
const clientId = String(process.env.REACT_APP_KEYCLOAK_CLIENT_ID);

const keycloak = new Keycloak({
  url: url,
  realm: realm,
  clientId: clientId,
});

export default keycloak;
