import $api from "..";
import { Keycloak } from "../../@types/keycloak";
import { ApiData } from "../../@types/type";

export const sendTokenOnBE = async (token: Keycloak): Promise<ApiData> => {
  return $api.post("/authorization/login/", { token }).then((data) => {
    return data.data;
  });
};

export const logout = async () => {
  try {
    const { data } = await $api.post("/authorization/logout/", {});
    return data;
  } catch (error) {
    console.error("Error during logout:", error);
    throw error;
  }
};

export const handleLogout = async () => {
  try {
    await logout();
    window.location.href = "/";
  } catch (error) {
    console.error("Logout error:", error);
  }
};
